import { actions } from '..';
import { links } from '../../api';
import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { IAddLinkRequestModel } from '../model';
import { IRootState } from '../../store';
import { makeValidString } from '../../utils/latinize';
import { designUrl } from '../../utils/apiClient';
import { ILink } from '../components/Main';

export default function*(action: ReturnType<typeof actions.addStart>) {
  try {
    const state: IRootState = yield select();
    const { id } = state.auth.user!;
    const { folder, redirectTo, slug } = action.payload;
    let inputURL = redirectTo;
    if (
      inputURL.substring(0, 7) !== 'http://' &&
      inputURL.substring(0, 8) !== 'https://'
    ) {
      inputURL = 'http://' + inputURL;
    }

    const folderName = folder
      ? folder === ''
        ? '_'
        : makeValidString(folder)
      : undefined;

    const link: IAddLinkRequestModel = {
      title: slug,
      redirectTo: inputURL,
      folder: folderName,
      slug: makeValidString(slug),
      userId: id,
    };
    const res = yield call(links.addLink, link);
    const getUrl = (x: ILink) => (x.folder ? `${x.folder}/${x.slug}` : x.slug);
    const linkResponse = {
      ...res,
      dateModified: moment(res.dateModified).local(),
      url: `${designUrl}${getUrl(res)}`,
    };
    yield put(actions.initStart());
    yield put(actions.addSuccess(linkResponse));
  } catch (ex) {
    yield put(actions.addFailure(ex.response && ex.response.error));
  }
}
