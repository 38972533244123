import React from 'react';
import { ILink } from './Main';
import { default as Table } from './Table';
import _ from 'lodash';

export interface IRow {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

export enum OrderType {
  asc = 'asc',
  desc = 'desc',
}

interface ITableContainerOwnProps {
  data: ILink[];
  rows: IRow[];
  onRowClick: (link: ILink) => void;
}

type IEnhancedTableProps = ITableContainerOwnProps;

interface IState {
  order: OrderType;
  orderBy: string;
  copiedLinkId: number | undefined;
}

class EnhancedTable extends React.Component<IEnhancedTableProps, IState> {
  state = {
    order: OrderType.desc,
    orderBy: 'dateModified',
    copiedLinkId: undefined,
  };

  handleRequestSort = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    property: string,
  ) => {
    const orderBy = property;
    let order = OrderType.desc;

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = OrderType.asc;
    }

    this.setState({ order, orderBy });
  };

  changedCopiedLink = (linkId: number) => {
    this.setState({
      copiedLinkId: linkId,
    });

    _.debounce(
      () =>
        this.setState({
          copiedLinkId: undefined,
        }),
      3000,
    )();
  };

  render() {
    const { data, rows, onRowClick } = this.props;
    const { order, orderBy, copiedLinkId } = this.state;

    return (
      <Table
        data={data}
        copiedLinkId={copiedLinkId}
        order={order}
        rows={rows}
        orderBy={orderBy}
        onRowClick={onRowClick}
        changedCopiedLink={this.changedCopiedLink}
        handleRequestSort={this.handleRequestSort}
      />
    );
  }
}

export default EnhancedTable;
