import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { all } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import {
  reducer as authReducer,
  IState as AuthState,
  rootSaga as authRootSaga
} from './auth';
import {
  reducer as mainReducer,
  IState as MainState,
  rootSaga as mainRootSaga
} from './main';

export interface IRootState {
  auth: AuthState;
  main: MainState;
}

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    main: mainReducer,
    auth: authReducer
  });

const sagaMiddleware = createSagaMiddleware();
function* rootSaga() {
  yield all([mainRootSaga(), authRootSaga()]);
}

const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
  console.groupCollapsed(action.type);
  console.log('state', store.getState());
  console.info('dispatching', action);
  console.groupEnd();
  const result = next(action);
  return result;
};

const configureStore = (history: History) => {
  const rootReducer = createRootReducer(history);
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history)
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);
  const store = createStore(rootReducer, undefined, composedEnhancers);
  sagaMiddleware.run(rootSaga as any);
  return store;
};

export default configureStore;
