import * as api from '../../api';
import * as m from '../model';
import { call, put } from 'redux-saga/effects';
import { actions as mainActions } from '../../main';
import security from '../../utils/security';

export function* getMe() {
  try {
    if (security.getToken()) {
      const user = yield call(api.auth.getMe);
      yield put(m.actions.getMeSuccess(user));
      yield put(mainActions.initStart());
    } else {
      yield put(m.actions.getMeSuccess(null));
    }
  } catch (ex) {
    yield put(m.actions.getMeFailure(ex.response && ex.response.error));
  }
}
