import { actions } from '..';
import { links } from '../../api';
import { call, put } from 'redux-saga/effects';
import { ILink } from '../components/Main';
import moment from 'moment';
import { designUrl } from '../../utils/apiClient';

export default function*(action: ReturnType<typeof actions.initStart>) {
  try {
    const res: ILink[] = yield call(links.getLinks);
    const getUrl = (x: ILink) => (x.folder ? `${x.folder}/${x.slug}` : x.slug);

    const mappedResponse = res.map(x => ({
      ...x,
      dateModified: moment.utc(x.dateModified).local(),
      url: `${designUrl}${getUrl(x)}`,
    }));
    yield put(actions.initSuccess(mappedResponse));
  } catch (ex) {
    yield put(actions.initFailure(ex.response && ex.response.error));
  }
}
