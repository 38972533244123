import { takeEvery, all } from 'redux-saga/effects';
import * as m from '../model';
import init from './init';
import editLink from './editLink';
import addLink from './addLink';
import deleteLink from './deleteLink';

export default function* rootSaga() {
  yield all([takeEvery(m.INIT_START, init)]);
  yield all([takeEvery(m.ADD_LINK_START, addLink)]);
  yield all([takeEvery(m.EDIT_LINK_START, editLink)]);
  yield all([takeEvery(m.DELETE_LINK_START, deleteLink)]);
}
