import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IRootState } from '../store';
import { returnTypeOf } from '../utils';
import { connect } from 'react-redux';
import { actions } from '../main';
import { links } from '../api';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
} from '@material-ui/core';
import ErrorLogo from '../assets/img/number.svg';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F4F5F7',
    },
    modal: {
      height: 498,
      width: 876,
      borderRadius: '11px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 10px 37px 0 rgba(0, 0, 0, 0.09)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    root: {
      height: '100%',
      minHeight: '100vh',
    },
    title: {
      fontSize: 60,
    },
    notFoundText: {
      color: '#616579',
      fontSize: '30px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    secondText: {
      maxWidth: 393,
      color: '#616579',
      fontSize: '20px',
      textAlign: 'center',
      marginTop: 4,
      marginBottom: 34,
    },
    thirdText: {
      cursor: 'pointer',
      color: '#616579',
      fontSize: 16,
      textAlign: 'center',
      borderRadius: 3,
      width: 153,
      backgroundColor: 'rgba(97,101,121,0.09)',
      padding: '11px 0px',
    },
    errorLogo: {
      marginBottom: 15,
    },
  });

interface IRouterProps {
  path: string;
  subpath: string;
}

const mapStateToProps = (state: IRootState) => ({
  main: state.main,
});

const stateProps = returnTypeOf(mapStateToProps);
type StateProps = typeof stateProps;
type DispatchProps = typeof actions;

type Props = RouteComponentProps<IRouterProps> &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles>;

interface IOwnState {
  isApiResponded: boolean;
}

class Redirect extends React.Component<Props, IOwnState> {
  state: IOwnState = {
    isApiResponded: false,
  };

  async componentWillMount() {
    const { path, subpath } = this.props.match.params;
    let response;
    if (path && subpath) {
      response = await links.getRedirectTo(path, subpath);
    } else if (path && !subpath) {
      response = await links.getRedirectTo('', path);
    }

    if (response && response.redirectTo) {
      window.location.replace(response.redirectTo);
    }
    if (response && response.redirectTo === null) {
      this.setState({
        isApiResponded: true,
      });
    }
  }

  // redirectToHomePage = () => {
  //   this.props.history.push('/');
  // };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.isApiResponded && (
          <div className={classes.container}>
            <div className={classes.modal}>
              <img src={ErrorLogo} alt="" className={classes.errorLogo} />
              <Typography className={classes.notFoundText}>
                Page not found
              </Typography>
              <Typography className={classes.secondText}>
                The page you are looking for doesn’t exist or has been moved.
              </Typography>
              {/* <Typography
                className={classes.thirdText}
                onClick={this.redirectToHomePage}
              >
                Back to home
              </Typography> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(Redirect)),
);
