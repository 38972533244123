import React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  ClickAwayListener,
} from '@material-ui/core';
import { Paper } from '../../components';
import closeIcon from '../../assets/img/close.svg';

const modalStyles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#616579',
      '&:hover': {
        color: '#616579',
      },
      '&:active': {
        color: '#616579',
      },
      '&:focus': {
        color: '#616579',
      },
    },
    active: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#616579',
    },
    container: {
      zIndex: 9000,
      background: 'transparent',
      height: '100%',
      width: '100%',
      position: 'fixed',
      overflow: 'hidden',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(66,70,94, 0.4)',
    },
    paper: {
      minHeight: 269,
      maxWidth: 627,
      width: '100%',
      borderRadius: 11,
      boxShadow: '0 10px 37px 0 rgba(0,0,0,0.09)',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 8,
        marginRight: 8,
      },
    },
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    closeIcon: {
      maxHeight: 24,
    },
    closeHandler: {
      padding: 20,
      cursor: 'pointer',
    },
  });

interface IOwnProps {
  isOpen?: boolean;
  onClose: () => void;
}

type IProps = WithStyles<typeof modalStyles> & IOwnProps;

class Modal extends React.Component<IProps> {
  componentDidMount() {
    document.getElementsByTagName('body').item(0)!.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.getElementsByTagName('body').item(0)!.style.overflow = '';
  }

  render() {
    const { classes, onClose } = this.props;
    return (
      <div className={classes.container}>
        <ClickAwayListener onClickAway={onClose}>
          <Paper className={classes.paper}>
            <div className={classes.closeButtonContainer}>
              <div className={classes.closeHandler} onClick={onClose}>
                <img src={closeIcon} className={classes.closeIcon} />
              </div>
            </div>
            {this.props.children}
          </Paper>
        </ClickAwayListener>
      </div>
    );
  }
}

export default withStyles(modalStyles)(Modal);
