import { IState, ActionTypes } from './';
import * as m from './model';

const initialState: IState = {
  links: undefined,
  error: undefined,
  lastAddedLink: undefined,
  lastUpdateLink: undefined,
};

export const reducer = (
  state: IState = initialState,
  action: ActionTypes,
): IState => {
  switch (action.type) {
    case m.INIT_START:
      return {
        ...state,
      };
    case m.INIT_SUCCESS:
      return {
        ...state,
        links: action.payload,
      };
    case m.INIT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case m.ADD_LINK_START:
      return {
        ...state,
        lastAddedLink: undefined,
      };
    case m.ADD_LINK_SUCCES:
      return {
        ...state,
        lastAddedLink: action.payload,
      };
    case m.ADD_LINK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case m.EDIT_LINK_START:
      return {
        ...state,
        lastUpdateLink: undefined,
      };
    case m.EDIT_LINK_SUCCES:
      return {
        ...state,
        lastUpdateLink: action.payload,
      };
    case m.EDIT_LINK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
