import { call, put } from 'redux-saga/effects';
import { actions } from '..';
import * as api from '../../api';
import { security } from '../../utils';
import { actions as mainActions } from '../../main';

export function* login(action: ReturnType<typeof actions.login>) {
  try {
    security.setToken('');
    const googleAuthResponse = action.payload.getAuthResponse();
    const res = yield call(api.auth.login, googleAuthResponse.id_token);
    security.setToken(res.token);
    yield put(
      actions.loginSuccess({
        email: res.email,
        id: res.id,
        name: res.name,
      }),
    );
    yield put(mainActions.initStart());
  } catch (ex) {
    security.setToken('');
    yield put(actions.loginFailure(ex.response && ex.response.error));
  }
}
