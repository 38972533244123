import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      maxWidth: 1350,
      width: '100%',
    },

    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#1E1E1E',
    },
    saveButton: {
      maxWidth: 100,
      width: '100%',
      background: '#FF8C00',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      marginRight: 10,
    },
    title: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '29px',
      paddingLeft: 57,
      paddingTop: 49,
      paddingBottom: 49,
    },
    modalInputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    input: {
      minWidth: 384,
    },
    modalButtonsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      paddingTop: 49,
      paddingBottom: 49,
      paddingRight: 92,
    },
  });
