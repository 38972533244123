import * as superagent from 'superagent';
import * as security from './security';
import { store } from '../App';
import { actions } from '../auth';

// local
// const domain = 'https://localhost:50000/';
// export const designUrl = 'localhost:3000/';

// prod
const domain = 'https://design-mediapark-api.azurewebsites.net/';
export const designUrl = `http://${window.location.hostname}/`;

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

const request = async (method: Method, url: string, data?: {}) => {
  const jwt = security.getToken();
  switch (method) {
    case 'GET':
      try {
        return await superagent
          .get(domain + url)
          .set('Authorization', `Bearer ${jwt}`)
          .then(res => res.body);
      } catch (ex) {
        if (ex.response.error.status === 401) {
          store.dispatch(actions.logout());
        }
        throw ex;
      }
    case 'POST':
      try {
        return await superagent
          .post(domain + url)
          .send(data)
          .set('Authorization', `Bearer ${jwt}`)
          .then(res => res.body);
      } catch (ex) {
        if (ex.response.error.status === 401) {
          store.dispatch(actions.logout());
        }
        throw ex;
      }
    case 'PUT':
      try {
        return await superagent
          .put(domain + url)
          .send(data)
          .set('Authorization', `Bearer ${jwt}`)
          .then(res => res.body);
      } catch (ex) {
        if (ex.response.error.status === 401) {
          store.dispatch(actions.logout());
        }
        throw ex;
      }
    case 'DELETE':
      try {
        return await superagent
          .delete(domain + url)
          .send(data)
          .set('Authorization', `Bearer ${jwt}`)
          .then(res => res.body);
      } catch (ex) {
        if (ex.response.error.status === 401) {
          store.dispatch(actions.logout());
        }
        throw ex;
      }
    default:
      return;
  }
};

export default {
  get: (url: string) => request('GET', url),
  post: (url: string, data?: {}) => request('POST', url, data),
  put: (url: string, data?: {}) => request('PUT', url, data),
  delete: (url: string) => request('DELETE', url),
};
