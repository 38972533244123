import React, { Component } from 'react';
import {
  withStyles,
  WithStyles,
  CircularProgress,
  withTheme,
  WithTheme,
} from '@material-ui/core';
import mainStyles from './mainStyles';
import { connect } from 'react-redux';
import * as authModel from '../../auth';
import * as mainModel from '..';
import { Login } from '../../auth';
import { IRootState } from '../../store';
import { returnTypeOf } from '../../utils';
import Table from './TableContainer';
import { Moment } from 'moment';
import AddLinkModal from './AddLinkModal';
import AddEditLinkSuccessModal from './AddEditLinkSuccessModal';
import EditLinkModal from './EditLinkModal';
import DeleteLinkConfirmModal from './DeleteLinkConfirmModal';
import PageHeader from './PageHeader';
import security from '../../utils/security';

// tslint:disable:jsx-no-lambda

export interface ILink {
  id: number;
  title: string;
  slug: string;
  folder: string;
  url: string;
  dateModified: Moment;
  redirectTo: string;
  lastEditedBy: string;
}

interface IState {
  searchSlug: string | undefined;
  isAddLinkModalOpen: boolean;
  isAddLinkSuccessModalOpen: boolean;
  isEditLinkModalOpen: boolean;
  isEditLinkSuccessModalOpen: boolean;
  isDeleteConfirmModalOpen: boolean;
  selectedLink: ILink | undefined;
}

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
  main: state.main,
});

const stateProps = returnTypeOf(mapStateToProps);
type StateProps = typeof stateProps;

type Props = WithStyles<typeof mainStyles> &
  typeof authModel.actions &
  typeof mainModel.actions &
  StateProps &
  WithTheme;

class Main extends Component<Props, IState> {
  state: IState = {
    searchSlug: undefined,
    isAddLinkModalOpen: false,
    isAddLinkSuccessModalOpen: false,
    isEditLinkSuccessModalOpen: false,
    isEditLinkModalOpen: false,
    isDeleteConfirmModalOpen: false,
    selectedLink: undefined,
  };

  componentDidMount() {
    const { getMe, initStart } = this.props;
    getMe();
    if (security.getToken()) {
      initStart();
    }
  }

  search = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    this.setState({
      searchSlug: event.target.value,
    });
  };

  handleAddSave = (
    folder: string | undefined,
    redirectTo: string,
    slug: string,
  ) => {
    this.setState({
      isAddLinkModalOpen: false,
      isAddLinkSuccessModalOpen: true,
    });
    this.props.addStart({
      folder,
      redirectTo,
      slug,
    });
  };

  handleEditSave = (
    id: number,
    folder: string | undefined,
    redirectTo: string,
    slug: string,
  ) => {
    this.setState({
      isEditLinkModalOpen: false,
      isEditLinkSuccessModalOpen: true,
    });
    this.props.editStart({
      id,
      folder,
      redirectTo,
      slug,
    });
  };

  handleAddCancel = () => {
    this.setState({
      isAddLinkModalOpen: false,
    });
  };

  handleEditCancel = () => {
    this.setState({
      isEditLinkModalOpen: false,
    });
  };

  handleAddLinkSuccessModalClose = () => {
    this.setState({
      isAddLinkSuccessModalOpen: false,
    });
  };

  handleEditLinkSuccessModalClose = () => {
    this.setState({
      isEditLinkSuccessModalOpen: false,
    });
  };

  handleOpenAddLinkModal = () => {
    this.setState({
      isAddLinkModalOpen: true,
    });
  };

  handleOpenEditLinkModal = (selectedLink: ILink) => {
    this.setState({
      isEditLinkModalOpen: true,
      selectedLink,
    });
  };

  handleDeleteConfirmWindow = () => {
    this.setState({
      isEditLinkModalOpen: false,
      isDeleteConfirmModalOpen: true,
    });
  };

  handleDeleteConfirmModalClose = () => {
    this.setState({
      isDeleteConfirmModalOpen: false,
    });
  };

  handleDelete = (id: number) => {
    this.setState({
      isDeleteConfirmModalOpen: false,
    });
    this.props.deleteStart(id);
  };

  render() {
    const { classes, auth, main, theme, logout } = this.props;
    const { user, loading } = auth;
    const {
      searchSlug,
      isAddLinkModalOpen,
      isAddLinkSuccessModalOpen,
      isEditLinkSuccessModalOpen,
      isEditLinkModalOpen,
      isDeleteConfirmModalOpen,
      selectedLink,
    } = this.state;
    const { lastAddedLink, lastUpdateLink } = main;
    let links = main.links;
    links = links ? links : [];

    if (loading) {
      return (
        <div className={classes.centeredContainer}>
          <CircularProgress />
        </div>
      );
    }

    if (!user) {
      return (
        <div className={classes.centeredContainer}>
          <Login />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <PageHeader
            onOpenAddLinkModal={this.handleOpenAddLinkModal}
            onLogout={logout}
            onSearch={this.search}
          />
          <Table
            data={
              searchSlug
                ? links.filter(
                    x =>
                      (x.title &&
                        x.title
                          .toLowerCase()
                          .includes(searchSlug && searchSlug.toLowerCase())) ||
                      (x.slug &&
                        x.slug
                          .toLowerCase()
                          .includes(searchSlug && searchSlug.toLowerCase())) ||
                      (x.folder &&
                        x.title &&
                        `${x.folder}/${x.title}`
                          .toLowerCase()
                          .includes(searchSlug && searchSlug.toLowerCase())) ||
                      (x.folder &&
                        x.slug &&
                        `${x.folder}/${x.title}`
                          .toLowerCase()
                          .includes(searchSlug && searchSlug.toLowerCase())),
                  )
                : links
            }
            // tslint:disable-next-line:jsx-no-lambda
            onRowClick={this.handleOpenEditLinkModal}
            rows={[
              {
                id: 'slug',
                numeric: false,
                disablePadding: false,
                label: 'Slug',
              },
              {
                id: 'dateModified',
                numeric: false,
                disablePadding: false,
                label: 'Date modified',
              },
              {
                id: 'lastEditedBy',
                numeric: false,
                disablePadding: false,
                label: 'Last edited by',
              },
              { id: 'copy', numeric: false, disablePadding: false, label: '' },
              { id: 'open', numeric: false, disablePadding: false, label: '' },
            ]}
          />
        </div>
        {isAddLinkModalOpen && (
          <AddLinkModal
            onSave={this.handleAddSave}
            onClose={this.handleAddCancel}
          />
        )}
        {isAddLinkSuccessModalOpen && lastAddedLink && (
          <AddEditLinkSuccessModal
            title={'Link created successfully'}
            url={lastAddedLink.url}
            onClose={this.handleAddLinkSuccessModalClose}
          />
        )}
        {isEditLinkModalOpen && (
          <EditLinkModal
            onSave={this.handleEditSave}
            onClose={this.handleEditCancel}
            onDelete={this.handleDeleteConfirmWindow}
            link={selectedLink}
          />
        )}
        {isEditLinkSuccessModalOpen && lastUpdateLink && (
          <AddEditLinkSuccessModal
            title={'Changes saved successfully'}
            url={lastUpdateLink.url}
            onClose={this.handleEditLinkSuccessModalClose}
          />
        )}
        {isDeleteConfirmModalOpen && (
          <DeleteLinkConfirmModal
            title="Delete the link?"
            link={selectedLink}
            onClose={this.handleDeleteConfirmModalClose}
            onDelete={this.handleDelete}
          />
        )}
      </div>
    );
  }
}

export default withTheme()(
  connect(
    mapStateToProps,
    { ...authModel.actions, ...mainModel.actions },
  )(withStyles(mainStyles)(Main)),
);
