import React from 'react';
import {
  Typography,
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  IconButton,
} from '@material-ui/core';
import Modal from './Modal';
import { ILink } from './Main';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '29px',
      paddingTop: 0,
      paddingBottom: 30,
      display: 'flex',
      justifyContent: 'center',
      wordBreak: 'break-word',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 45,
        marginRight: 45,
        textAlign: 'center',
      },
    },
    modalButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingRight: 0,
        alignItems: 'center',
        paddingTop: 0,
      },
    },
    input: {
      minWidth: 384,
      borderRadius: '3px 0 0 3px',
      borderRight: 0,
    },
    subTitle: {
      color: '#616579',
      fontSize: 16,
      lineHeight: '20px',
      paddingBottom: 46,
      justifyContent: 'center',
      display: 'flex',
    },
    greyButton: {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#EFF1F5',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#3C3E4B',
      lineHeight: '20px',
      marginRight: 17,
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.2)',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
        marginRight: 10,
        marginLeft: 10,
        maxWidth: 305,
      },
    },
    deleteButton: {
      maxWidth: 100,
      width: '100%',
      background: '#FF8C00',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      '&:hover': {
        background: '#FED731',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        marginLeft: 10,
        maxWidth: 305,
        marginBottom: 15,
      },
    },
  });

interface IOwnProps {
  title: string;
  link: ILink | undefined;
  onClose: () => void;
  onDelete: (id: number) => void;
}

type Props = WithStyles<typeof styles> & IOwnProps;

class AddEditLinkSuccessModal extends React.Component<Props> {
  handleDelete = () => {
    const { link, onDelete } = this.props;
    if (link) {
      onDelete(link.id);
    }
  };

  render() {
    const { classes, link, title, onClose } = this.props;

    if (!link) {
      return null;
    }

    return (
      <Modal isOpen={true} onClose={onClose}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subTitle}>{link.url}</Typography>
        <div className={classes.modalButtonContainer}>
          <Button className={classes.greyButton} onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button className={classes.deleteButton} onClick={this.handleDelete}>
            Delete
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddEditLinkSuccessModal);
