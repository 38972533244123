import { actions } from '..';
import { links } from '../../api';
import { call, put, select } from 'redux-saga/effects';
import { ILink } from '../components/Main';
import moment from 'moment';
import { IRootState } from '../../store';
import { IEditLinkRequestModel } from '../model';
import { makeValidString } from '../../utils/latinize';
import { designUrl } from '../../utils/apiClient';

export default function*(action: ReturnType<typeof actions.editStart>) {
  try {
    const state: IRootState = yield select();
    const { id: userId } = state.auth.user!;
    const { folder, redirectTo, slug, id: linkId } = action.payload;
    let inputURL = redirectTo;

    if (
      inputURL.substring(0, 7) !== 'http://' &&
      inputURL.substring(0, 8) !== 'https://'
    ) {
      inputURL = 'http://' + inputURL;
    }
    const folderName = folder
      ? folder === ''
        ? '_'
        : makeValidString(folder)
      : undefined;

    const link: IEditLinkRequestModel = {
      id: linkId,
      title: slug,
      redirectTo: inputURL,
      folder: folderName,
      slug: makeValidString(slug),
      userId,
    };

    const res = yield call(links.editLink, link);
    const getUrl = (x: ILink) => (x.folder ? `${x.folder}/${x.slug}` : x.slug);
    const linkResponse = {
      ...res,
      dateModified: moment(res.dateModified).local(),
      url: `${designUrl}${getUrl(res)}`,
    };
    yield put(actions.initStart());
    yield put(actions.editSuccess(linkResponse));
  } catch (ex) {
    yield put(actions.editFailure(ex.response && ex.response.error));
  }
}
