import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import paperStyles from './paperStyles';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';

interface IOwnProps {
  children?: React.ReactNode;
  className?: string;
  id?: string | number;
  onClick?: () => void;
}
type Props = IOwnProps & WithStyles<typeof paperStyles>;

const OwnPaper = ({ id, children, classes, className, onClick }: Props) => {
  return (
    <div
      key={id}
      className={classNames(className, classes.paper, {
        [classes.pointer]: onClick
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default withStyles(paperStyles)(OwnPaper);
