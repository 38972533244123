import React from 'react';
import {
  Typography,
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import { Input } from '../../components';
import Modal from './Modal';
import LinkPreview from './LinkPreview';

const styles = (theme: Theme) =>
  createStyles({
    greyButton: {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#EFF1F5',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#3C3E4B',
      lineHeight: '20px',
      marginRight: 17,
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.2)',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        marginLeft: 10,
        maxWidth: 305,
      },
    },
    saveButton: {
      maxWidth: 100,
      width: '100%',
      background: '#FF8C00',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      '&:hover': {
        background: '#FED731',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        marginLeft: 10,
        marginBottom: 15,
        maxWidth: 305,
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '29px',
      paddingLeft: 57,
      paddingTop: 0,
      paddingBottom: 16,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
        paddingBottom: 36,
        paddingLeft: 40,
      },
    },
    modalInputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingLeft: 50,
      paddingRight: 50,
    },
    input: {
      minWidth: 384,
      [theme.breakpoints.down('sm')]: {
        minWidth: 0,
        width: '100%',
      },
    },
    modalButtonsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      paddingTop: 0,
      paddingBottom: 40,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        paddingRight: 0,
        alignItems: 'center',
        paddingTop: 0,
      },
    },
    modalPreviewContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 24,
      paddingBottom: 32,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingRight: 0,
        alignItems: 'center',
        paddingTop: 0,
      },
    },
    inputGroup: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        right: '3px',
        position: 'relative',
      },
    },
    inputFormControl: {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        right: 9,
      },
    },
  });

interface IOwnProps {
  onClose: () => void;
  onSave: (
    folder: string | undefined,
    redirectTo: string,
    slug: string,
  ) => void;
}

type Props = WithStyles<typeof styles> & IOwnProps;
interface IState {
  redirectTo: string | undefined;
  slug: string | undefined;
  folder: string | undefined;
  isTriedSave: boolean;
}

class AddLinkModal extends React.Component<Props, IState> {
  state: IState = {
    redirectTo: undefined,
    slug: undefined,
    folder: undefined,
    isTriedSave: false,
  };

  changeRedirectTo = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    this.setState({
      redirectTo: event.target.value,
    });
  };

  changeSlug = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    this.setState({
      slug: event.target.value.replace(/\s+/g, '_'),
    });
  };

  changeFolder = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    this.setState({
      folder: event.target.value.replace(/\s+/g, '_'),
    });
  };

  handleSave = () => {
    const { folder, slug, redirectTo } = this.state;
    if (slug && redirectTo) {
      this.props.onSave(folder, redirectTo, slug);
    }
    this.setState({
      isTriedSave: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { redirectTo, slug, isTriedSave } = this.state;
    return (
      <Modal isOpen={true} onClose={this.props.onClose}>
        <Typography className={classes.title}>Add new link</Typography>
        <div className={classes.modalInputContainer}>
          <div className={classes.inputGroup}>
            <Input
              id="1"
              label="Redirect to"
              required={true}
              placeholder="Paste design URL here"
              inputClassName={classes.input}
              inputFormControlClassName={classes.inputFormControl}
              error={
                (isTriedSave && !redirectTo && 'Field is required') || undefined
              }
              onChange={this.changeRedirectTo}
            />
            <Input
              id="2"
              label="Slug"
              placeholder="Add slug"
              required={true}
              inputClassName={classes.input}
              inputFormControlClassName={classes.inputFormControl}
              error={(isTriedSave && !slug && 'Field is required') || undefined}
              onChange={this.changeSlug}
            />
            <Input
              id="3"
              label="Folder name"
              placeholder="Add folder"
              inputClassName={classes.input}
              inputFormControlClassName={classes.inputFormControl}
              onChange={this.changeFolder}
            />
          </div>
          <div className={classes.modalPreviewContainer}>
            <LinkPreview
              folderName={this.state.folder}
              slug={this.state.slug}
            />
          </div>
          <div className={classes.modalButtonsContainer}>
            <Button className={classes.greyButton} onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button className={classes.saveButton} onClick={this.handleSave}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddLinkModal);
