import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    paper: {
      height: 498,
      width: 876,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1E1E1E',
      boxShadow: 'none',
      [theme.breakpoints.down('sm')]: {
        width: 350,
        height: 300,
      },
    },
    text: {
      color: '#000000',
      fontSize: 19,
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    brandContainer: {
      marginTop: 35,
      marginBottom: 53,
      [theme.breakpoints.down('sm')]: {
        marginTop: 15,
      },
    },
    button: {
      height: 50,
      width: 370,
      borderRadius: 3,
      backgroundColor: '#F0F0F2',
      border: 0,
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: 285,
      },
    },
    googleIconContainer: {
      height: 49,
      width: 80,
      borderRadius: '0 3px 3px 0',
      backgroundColor: '#E9E9EC',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    googleIcon: {
      height: 32,
    },
    signInText: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
    popup: {
      backgroundColor: 'red',
    },
    img: {
      width: '100%',
      maxWidth: '300px',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 200,
      },
    },
    mainText: {
      width: 385,
      textAlign: 'center',
      color: '#FFFFFF',
    },
  });
