import apiClient from '../utils/apiClient';
import { ILink } from '../main/components/Main';
import { IEditLinkRequestModel, IAddLinkRequestModel } from '../main/model';
import { getLinkType } from '../utils/links';

const host = window.location.hostname;

const linkType = getLinkType(host);

export const getLinks = (): Promise<{ links: ILink[] }> | undefined =>
  apiClient.get(`api/links?linkType=${linkType}`);

export const getRedirectTo = (
  folder: string,
  slug: string,
): Promise<{ redirectTo: string }> | undefined =>
  apiClient.get(
    `api/links/redirectTo?linkType=${linkType}&folder=${folder}&slug=${slug}`,
  );

export const editLink = (
  link: IEditLinkRequestModel,
): Promise<{ link: ILink }> | undefined => apiClient.put(`api/links`, link);

export const deleteLink = (
  id: number,
): Promise<{ id: number | undefined }> | undefined =>
  apiClient.delete(`api/links/${id}`);

export const addLink = (
  link: IAddLinkRequestModel,
): Promise<{ link: ILink }> | undefined =>
  apiClient.post(`api/links?linkType=${linkType}`, link);

export default {
  getLinks,
  editLink,
  deleteLink,
  addLink,
  getRedirectTo,
};
