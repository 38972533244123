import { ActionsUnion } from '../../typings/common';
import { IRootState } from '../store';
import { GoogleLoginResponse } from 'react-google-login';
import { IUser } from '.';
import { default as IError } from '../model/interfaces/IError';

// Action types

export const LOGIN_START = 'auth/LOGIN_START';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const LOGOUT = 'auth/LOGOUT';
export const GET_ME_START = 'auth/GET_ME_START';
export const GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'auth/GET_ME_FAILURE';

// Action Creators

export const actions = {
  login: (googleLoginResponse: GoogleLoginResponse) => ({
    type: LOGIN_START as typeof LOGIN_START,
    payload: googleLoginResponse,
  }),
  loginSuccess: (user: IUser) => ({
    type: LOGIN_SUCCESS as typeof LOGIN_SUCCESS,
    payload: user,
  }),
  loginFailure: (error: IError | undefined) => ({
    type: LOGIN_FAILURE as typeof LOGIN_FAILURE,
    payload: error,
  }),
  logout: () => ({
    type: LOGOUT as typeof LOGOUT,
  }),
  getMeSuccess: (user: IUser | null) => ({
    type: GET_ME_SUCCESS as typeof GET_ME_SUCCESS,
    payload: user,
  }),
  getMe: () => ({
    type: GET_ME_START as typeof GET_ME_START,
  }),
  getMeFailure: (error: IError | undefined) => ({
    type: GET_ME_FAILURE as typeof GET_ME_FAILURE,
    payload: error,
  }),
};

export type ActionTypes = ActionsUnion<typeof actions>;

// Selectors

export const selectors = {
  getOwn: (state: IRootState) => state.auth,
};
