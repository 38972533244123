import apiClient from '../utils/apiClient';
import { IUser } from '../auth';

export const login = (
  tokenId: string
): Promise<{ token: string }> | undefined =>
  apiClient.post('api/auth/login', { tokenId });

export const getMe = (): Promise<IUser> => apiClient.get('api/auth/me');

export default {
  login,
  getMe
};
