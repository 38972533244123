import React from 'react';
import {
  WithStyles,
  withStyles,
  FormControl,
  Typography,
  Input,
} from '@material-ui/core';
import inputStyles from './inputStyles';
import classNames from 'classnames';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { any } from 'prop-types';

interface IOwnProps {
  id: string;
  EndAdornment?: any;
  Button?: any;
  placeholder?: string;
  label?: string | Element;
  inputClassName?: string;
  inputFormControlClassName?: string;
  required?: boolean;
  error?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  value?: string | undefined;
}

type Props = WithStyles<typeof inputStyles> & IOwnProps;

const OwnInput = ({
  classes,
  label,
  id,
  placeholder,
  inputClassName,
  inputFormControlClassName,
  value,
  required,
  EndAdornment,
  error,
  onChange,
}: Props) => {
  return (
    <FormControl
      id={`formControl_${id}`}
      className={classNames(
        inputFormControlClassName,
        classes.inputFormControl,
      )}
    >
      <div className={classes.inputContainer}>
        {label && (
          <Typography id={`inputLabel_${id}`} className={classes.label}>
            {label}
            {required && <span className={classes.required}>*</span>}
          </Typography>
        )}
        <Input
          disableUnderline={true}
          className={classNames(inputClassName, classes.input)}
          endAdornment={EndAdornment}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </div>
      {error && (
        <Typography className={classes.error} variant="caption">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default withStyles(inputStyles)(OwnInput);
