import React from 'react';
import {
  Typography,
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import Trash from '../../assets/img/trash.svg';
import { Input } from '../../components';
import Modal from './Modal';
import { ILink } from './Main';
import LinkPreview from './LinkPreview';

const styles = (theme: Theme) =>
  createStyles({
    greyButton: {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#EFF1F5',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#3C3E4B',
      lineHeight: '20px',
      marginRight: 17,
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.09)',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        marginLeft: 10,
        marginBottom: 15,
        maxWidth: 305,
      },
    },
    saveButton: {
      maxWidth: 100,
      width: '100%',
      background: '#FF8C00',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      '&:hover': {
        background: '#FED731',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 15,
        maxWidth: 305,
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '29px',
      paddingLeft: 55,
      paddingTop: 0,
      paddingBottom: 16,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
        paddingBottom: 36,
        paddingLeft: 40,
      },
    },
    modalInputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingLeft: 50,
      paddingRight: 50,
    },
    input: {
      minWidth: 384,
      [theme.breakpoints.down('sm')]: {
        minWidth: 0,
        width: '100%',
      },
    },
    modalButtonsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingTop: 0,
      paddingBottom: 40,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        paddingRight: 0,
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 30,
      },
    },
    subTitle: {
      color: '#616579',
      fontSize: 16,
      lineHeight: '20px',
      paddingBottom: 49,
      paddingLeft: 84,
      paddingTop: 5,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 22,
        paddingBottom: 32,
      },
    },
    rightButtonsContainer: {
      display: 'flex',
      width: 274,
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    deleteButton: {
      '&:hover': {
        background: '#EFF1F5',
      },
      padding: '0 10px',
      marginLeft: -10,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginLeft: 0,
        width: 204,
        marginTop: 17,
        height: 40,
      },
    },
    deleteButtonText: {
      color: '#616579',
    },
    cancelButton: {
      color: '#3C3E4B',
      background: '#EFF1F5',
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.2)',
      },
    },
    trashIcon: {
      paddingRight: 12,
    },
    modalPreviewContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 24,
      paddingBottom: 32,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingRight: 0,
        alignItems: 'center',
        paddingTop: 0,
      },
    },
    inputGroup: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        right: '3px',
        position: 'relative',
      },
    },
    inputFormControl: {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        right: 9,
      },
    },
    required: {
      color: '#F03738',
    },
  });

interface IOwnProps {
  onClose: () => void;
  onSave: (
    id: number,
    folder: string | undefined,
    redirectTo: string,
    slug: string,
  ) => void;
  onDelete: (id: number) => void;
  link: ILink | undefined;
}

type Props = WithStyles<typeof styles> & IOwnProps;
interface IState {
  redirectTo: string | undefined;
  slug: string | undefined;
  folder: string | undefined;
  isTriedSave: boolean;
}

class EditLinkModal extends React.Component<Props, IState> {
  state: IState = {
    redirectTo: this.props.link && this.props.link.redirectTo,
    slug: this.props.link && this.props.link.title,
    folder: this.props.link && this.props.link.folder,
    isTriedSave: false,
  };

  changeRedirectTo = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    this.setState({
      redirectTo: event.target.value,
    });
  };

  changeSlug = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    this.setState({
      slug: event.target.value.replace(/\s+/g, '_'),
    });
  };

  changeFolder = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { value } = event.target;
    this.setState({
      folder: value === '' ? undefined : value.replace(/\s+/g, '_'),
    });
  };

  handleSave = () => {
    const { folder, slug, redirectTo } = this.state;
    const { link } = this.props;
    if (link && link.id && slug && redirectTo) {
      this.props.onSave(link.id, folder, redirectTo, slug);
    }
    this.setState({
      isTriedSave: true,
    });
  };

  render() {
    const { classes, link } = this.props;
    const { folder, slug, redirectTo, isTriedSave } = this.state;

    if (!link) {
      return null;
    }

    return (
      <Modal onClose={this.props.onClose}>
        <Typography className={classes.title}>Edit link</Typography>
        <div className={classes.modalInputContainer}>
          <div className={classes.inputGroup}>
            <Input
              id="1"
              label="Redirect to"
              required={true}
              placeholder="Paste design URL here"
              inputClassName={classes.input}
              inputFormControlClassName={classes.inputFormControl}
              error={
                (isTriedSave && !redirectTo && 'Field is required') || undefined
              }
              onChange={this.changeRedirectTo}
              value={redirectTo}
            />
            <Input
              id="2"
              label="Slug"
              required={true}
              placeholder="Add slug"
              inputClassName={classes.input}
              inputFormControlClassName={classes.inputFormControl}
              error={(isTriedSave && !slug && 'Field is required') || undefined}
              onChange={this.changeSlug}
              value={slug}
            />
            <Input
              id="2"
              label="Folder:"
              placeholder="Add folder"
              inputClassName={classes.input}
              inputFormControlClassName={classes.inputFormControl}
              onChange={this.changeFolder}
              value={folder || ''}
            />
          </div>
          <div className={classes.modalPreviewContainer}>
            <LinkPreview
              slug={this.state.slug}
              folderName={this.state.folder}
            />
          </div>
          <div className={classes.modalButtonsContainer}>
            <Button
              className={`${classes.deleteButton}`}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => this.props.onDelete(link.id)}
            >
              <img src={Trash} alt="" className={classes.trashIcon} />
              <Typography className={classes.deleteButtonText}>
                Delete link
              </Typography>
            </Button>
            <div className={classes.rightButtonsContainer}>
              <Button
                className={`${classes.greyButton} ${classes.cancelButton}`}
                onClick={this.props.onClose}
              >
                Cancel
              </Button>
              <Button className={classes.saveButton} onClick={this.handleSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(EditLinkModal);
