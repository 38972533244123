import React from 'react';
import {
  WithStyles,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  withStyles,
  createStyles,
  Theme,
  SvgIcon,
  Icon,
  withTheme,
  WithTheme,
} from '@material-ui/core';
import { OrderType, IRow } from './TableContainer';
import Triangle from '../../assets/img/triangle.svg';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

const enhancedTableHeadStyles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#616579',
      '&:hover': {
        color: '#616579',
      },
      '&:active': {
        color: '#616579',
      },
      '&:focus': {
        color: '#616579',
      },
    },
    mobileTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#616579',
      '&:hover': {
        color: '#616579',
      },
      '&:active': {
        color: '#616579',
      },
      '&:focus': {
        color: '#616579',
      },
    },
    active: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#616579',
    },
    mobileActive: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#616579',
    },
    img: {
      height: 8,
      position: 'relative',
      top: -7,
    },
    icon: {
      height: 14,
      width: 14,
    },
    mobileCell: {
      paddingLeft: 10,
      paddingRight: 10,
      borderBottom: 0,
      '&:last-child': {
        paddingRight: 0,
      },
    },
    mobileHead: {
      background: '#F2F5FA',
      '& > div': {
        justifyContent: 'space-evenly',
        display: 'flex',
        alignItems: 'center',
      },
    },
    tableHeadingCell: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  });

interface IEnhancedTableHeadOwnProps {
  order: OrderType;
  orderBy: string;
  onRequestSort: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    property: string,
  ) => void;
  rows: IRow[];
}

type IEnhancedTableHeadProps = IEnhancedTableHeadOwnProps &
  WithStyles<typeof enhancedTableHeadStyles> &
  WithTheme;

const EnhancedTableHead = (props: IEnhancedTableHeadProps) => {
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    props.onRequestSort(event, property);
  };

  const Arrow = (arrowProps: any) => (
    <Icon
      {...arrowProps}
      className={arrowProps.className + ' ' + props.classes.icon}
    >
      <img src={Triangle} alt="" className={props.classes.img} />
    </Icon>
  );

  const { order, orderBy, rows, classes, theme } = props;
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (matches) {
    return (
      <TableHead className={classes.mobileHead}>
        <TableRow>
          {rows
            .filter(
              x =>
                x.id === 'slug' ||
                x.id === 'dateModified' ||
                x.id === 'lastEditedBy',
            )
            .map(row => (
              <TableCell
                key={row.id}
                align="center"
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.mobileCell}
              >
                {row.label && (
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={createSortHandler(row.id)}
                    className={classes.mobileTitle}
                    classes={{
                      active: classes.mobileActive,
                    }}
                    IconComponent={Arrow}
                  >
                    {row.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableHead>
      <TableRow>
        {rows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className={classes.tableHeadingCell}
          >
            {row.label && (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
                className={classes.title}
                classes={{
                  active: classes.active,
                }}
                IconComponent={Arrow}
              >
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default withTheme()(
  withStyles(enhancedTableHeadStyles)(EnhancedTableHead),
);
