import React from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const linkPreviewStyles = (theme: Theme) =>
  createStyles({
    lightGrayText: {
      color: '#DADAE0',
    },
    grayText: {
      color: '#3C3E4B',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    container: {
      background: '#F7F8FA',
      borderRadius: 4,
      padding: 16,
      width: '100%',
    },
  });

interface IOwnProps {
  folderName?: string | undefined;
  slug: string | undefined;
}

type IProps = WithStyles<typeof linkPreviewStyles> & IOwnProps;

class LinkPreview extends React.Component<IProps> {
  render() {
    const { classes, folderName, slug } = this.props;
    const host = window.location.hostname;
    return (
      <div className={classes.container}>
        <Typography>Link Preview</Typography>
        <div>
          <Typography className={classes.grayText}>
            {host}/
            <span
              className={
                folderName === undefined || ''
                  ? classes.lightGrayText
                  : classes.grayText
              }
            >
              {folderName === undefined || '' ? 'folder_name' : folderName}
            </span>
            {folderName !== '' && folderName !== null && '/'}
            <span
              className={
                slug === undefined || ''
                  ? classes.lightGrayText
                  : classes.grayText
              }
            >
              {slug === undefined || '' ? 'slug' : slug}
            </span>
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(linkPreviewStyles)(LinkPreview);
