export enum LinkType {
  Design,
  Prototype,
  Animation,
  Book,
}

export const getLinkType = (host: string) => {
  if (
    host === 'design.mediapark.lt' ||
    host === 'design.mediapark.com' ||
    host === 'dizainas.mediapark.lt'
  ) {
    return LinkType.Design;
  } else if (
    host === 'prototype.mediapark.lt' ||
    host === 'prototype.mediapark.com' ||
    host === 'wireframe.mediapark.com' ||
    host === 'prototipas.mediapark.lt' ||
    host === 'prototipas.mediapark.com'
  ) {
    return LinkType.Prototype;
  } else if (host === 'book.mediapark.lt' || host === 'book.mediapark.com') {
    return LinkType.Book;
  } else {
    return LinkType.Animation;
  }
};
