import React from 'react';
import {
  withTheme,
  WithTheme,
  Typography,
  Table,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import TableHeader from './TableHeader';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ILink } from './Main';
import Check from '../../assets/img/check.svg';
import Copy from '../../assets/img/copy.svg';
import OpenLink from '../../assets/img/open-link.svg';
import { IRow, OrderType } from './TableContainer';
import ImageIcon from '../../assets/img/image.svg';

const tableStyles = (theme: Theme) =>
  createStyles({
    tableWrapper: {
      paddingLeft: 50,
      paddingRight: 50,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    tableCellText: {
      color: '#616579',
      fontSize: 16,
      lineHeight: '20px',
      paddingTop: 6,
      height: 38,
      wordBreak: 'break-word',
      paddingRight: 15,
      paddingLeft: 15,
    },
    tableCellTextWithButton: {
      color: '#616579',
      fontSize: 16,
      lineHeight: '20px',
      height: 38,
      paddingRight: 15,
      paddingLeft: 15,
    },
    button: {
      height: 30,
      width: 122,
      border: 0,
      borderRadius: 3,
      background: 'transparent',
      cursor: 'pointer',
      transition: 'background-color 250ms',
      '&:focus': {
        outline: 0,
      },
      '&:active': {
        backgroundColor: '#FED731',
      },
      '&:hover': {
        backgroundColor: '#FED731',
        transition: 'background-color 250ms',
      },
    },
    buttonText: {
      color: '#616579',
      fontSize: 16,
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    copiedLinkButton: {
      height: 30,
      width: 122,
      border: '0',
      borderRadius: 3,
      backgroundColor: '#6DE1AF',
      '&:focus': {
        outline: 0,
      },
    },
    copiedLinkButtonText: {
      color: 'white',
      fontSize: 16,
      lineHeight: '20px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    ownTableRow: {
      display: 'flex',
      paddingLeft: 18,
      paddingRight: 18,
      height: 56,
      alignItems: 'center',
    },
    tableCellTextMobile: {
      color: '#616579',
      fontSize: 18,
      lineHeight: '20px',
      paddingTop: 6,
      width: '100%',
      wordBreak: 'break-word',
      paddingRight: 17,
    },
    tableCellTextWithButtonMobile: {
      color: '#616579',
      fontSize: 16,
      lineHeight: '20px',
    },
    divider: {
      border: '0.5px solid #E1E1E6',
    },
    buttonMobile: {
      height: 30,
      width: 87,
      border: '1px solid #DADAE0',
      borderRadius: 3,
      background: 'transparent',
      transition: 'background-color 250ms',
      '&:focus': {
        outline: 0,
      },
      '&:active': {
        border: '1px solid #DADAE0',
      },
      '&:hover': {
        backgroundColor: '#FED731',
        transition: 'background-color 250ms',
      },
    },
    copiedLinkButtonMobile: {
      height: 30,
      width: 87,
      border: '0',
      borderRadius: 3,
      backgroundColor: '#6DE1AF',
      transition: 'background-color 250ms',
      '&:focus': {
        outline: 0,
      },
      '&:hover': {
        backgroundColor: '#FED731',
        transition: 'background-color 250ms',
      },
    },
    linkButton: {
      height: 30,
      width: 122,
      border: '1px solid #DADAE0',
      borderRadius: 3,
      background: 'transparent',
      cursor: 'pointer',
      transition: 'background-color 250ms',
      textDecoration: 'none',
      color: 'initial',
      '&:focus': {
        outline: 0,
      },
      '&:active': {
        border: '1px solid #DADAE0',
      },
      '&:hover': {
        backgroundColor: '#FED731',
        transition: 'background-color 250ms',
      },
    },
    tableRow: {
      cursor: 'pointer',
      transition: 'background-color 250ms',
      '&:hover': {
        backgroundColor: '#FFF2AB!important',
        transition: 'background-color 250ms',
      },
    },
    assetIcon: {
      marginRight: 14,
      position: 'relative',
      bottom: -3,
    },
    widerTableCell: {
      [theme.breakpoints.up('md')]: {
        minWidth: 175,
        paddingRight: 20,
      },
    },
  });

const desc = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array: any, cmp: any) => {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};

const getSorting = (order: OrderType, orderBy: string) => {
  return order === OrderType.desc
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
};

interface IEnhancedTableOwnProps {
  data: ILink[];
  rows: IRow[];
  order: OrderType;
  orderBy: string;
  copiedLinkId: number | undefined;
  onRowClick: (link: ILink) => void;
  changedCopiedLink: (linkId: number) => void;
  handleRequestSort: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    property: string,
  ) => void;
}

type Props = WithTheme &
  WithStyles<typeof tableStyles> &
  IEnhancedTableOwnProps;

const EnhancedTable = (props: Props) => {
  const {
    classes,
    data,
    rows,
    theme,
    order,
    orderBy,
    copiedLinkId,
    onRowClick,
    changedCopiedLink,
    handleRequestSort,
  } = props;
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const mappedData = data.map(x => ({
    ...x,
    slug: `${x.folder ? x.folder + '/' : ''}${x.slug}`,
  }));

  const addDefaultSrc = (event: any) => {
    event.target.src = ImageIcon;
  };

  const renderAssetIcon = (url: string) => {
    if (url.includes('invisionapp') || url.includes('invis')) {
      return `https://www.google.com/s2/favicons?domain=invisionapp.com/`;
    } else {
      const hostName = new URL(url).host;

      return `https://www.google.com/s2/favicons?domain=${hostName}`;
    }
  };

  if (matches) {
    return (
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle">
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rows={rows}
          />
        </Table>
        <div>
          {stableSort(mappedData, getSorting(order, orderBy)).map(
            (x: ILink) => {
              return (
                // tslint:disable:jsx-no-lambda
                <div key={x.id}>
                  <div className={classes.ownTableRow}>
                    <div
                      className={classes.tableCellTextMobile}
                      onClick={() => onRowClick(x)}
                    >
                      <img
                        src={renderAssetIcon(x.redirectTo)}
                        className={classes.assetIcon}
                        onError={addDefaultSrc}
                      />
                      <span>{x.slug}</span>
                    </div>
                    <div className={classes.tableCellTextWithButtonMobile}>
                      {copiedLinkId === x.id ? (
                        <button className={classes.copiedLinkButtonMobile}>
                          <Typography className={classes.copiedLinkButtonText}>
                            <img src={Check} alt="" /> Copied
                          </Typography>
                        </button>
                      ) : (
                        <CopyToClipboard
                          text={x.url}
                          onCopy={() => changedCopiedLink(x.id)}
                        >
                          <button className={classes.buttonMobile}>
                            <Typography className={classes.buttonText}>
                              <img src={OpenLink} alt="" /> Copy
                            </Typography>
                          </button>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                  <div className={classes.divider} />
                </div>
              );
            },
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.tableWrapper}>
      <Table aria-labelledby="tableTitle">
        <TableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rows={rows}
        />
        <TableBody>
          {stableSort(mappedData, getSorting(order, orderBy)).map(
            (x: ILink) => {
              return (
                // tslint:disable:jsx-no-lambda
                <TableRow
                  hover={true}
                  tabIndex={-1}
                  key={x.id}
                  className={classes.tableRow}
                >
                  <TableCell
                    align="left"
                    className={classes.tableCellText}
                    onClick={() => onRowClick(x)}
                  >
                    <img
                      src={renderAssetIcon(x.redirectTo)}
                      className={classes.assetIcon}
                      onError={addDefaultSrc}
                    />
                    <span>{x.slug}</span>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`${classes.tableCellText} ${
                      classes.widerTableCell
                    }`}
                    onClick={() => onRowClick(x)}
                  >
                    {x.dateModified.format('YYYY-MM-DD, HH:mm')}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableCellText}
                    onClick={() => onRowClick(x)}
                  >
                    {x.lastEditedBy}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableCellTextWithButton}
                  >
                    {copiedLinkId === x.id ? (
                      <button className={classes.copiedLinkButton}>
                        <Typography className={classes.copiedLinkButtonText}>
                          <img src={Check} alt="" /> Link copied
                        </Typography>
                      </button>
                    ) : (
                      <CopyToClipboard
                        text={x.url}
                        onCopy={() => changedCopiedLink(x.id)}
                      >
                        <button className={classes.button}>
                          <Typography className={classes.buttonText}>
                            <img src={Copy} alt="" /> Copy link
                          </Typography>
                        </button>
                      </CopyToClipboard>
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableCellTextWithButton}
                  >
                    <a href={x.redirectTo} target="_blank">
                      <button className={classes.button}>
                        <Typography className={classes.buttonText}>
                          <img src={OpenLink} alt="" />
                          Open link
                        </Typography>
                      </button>
                    </a>
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default withTheme()(withStyles(tableStyles)(EnhancedTable));
