export const getToken = () => {
  return localStorage.getItem('jwt');
};

export const setToken = (value: string) => {
  localStorage.setItem('jwt', value);
};

export default {
  getToken,
  setToken,
};
