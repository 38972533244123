import React from 'react';
import {
  Typography,
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import { logout } from '../../auth/sagas/logout';
import { Input } from '../../components';
import Modal from './Modal';
import Copy from '../../assets/img/copyWhite.svg';
import CopyToClipboard from 'react-copy-to-clipboard';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      maxWidth: 122,
      width: '100%',
      background: '#FF8C00',
      borderRadius: 4,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      border: 0,
      transition: 'background-color 250ms',
      '&:focus': {
        outline: 0,
      },
      '&:active': {
        border: '1px solid #DADAE0',
      },
      '&:hover': {
        background: '#FED731',
        transition: 'background-color 250ms',
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: 3,
        maxWidth: 315,
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '29px',
      paddingBottom: 26,
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: 40,
    },
    modalInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 14,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 55,
      },
    },
    input: {
      minWidth: 510,
      borderRadius: 3,
      [theme.breakpoints.down('sm')]: {
        borderRadius: 3,
        minWidth: 0,
        width: '100%',
        borderRight: '1px solid #DADAE0',
      },
    },
    buttonText: {
      color: 'white',
      fontSize: 16,
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    greyButtonText: {
      color: '#3C3E4B',
      fontSize: 16,
      lineHeight: '20px',
    },
    inputFormControl: {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
      },
    },
    img: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 8,
      },
    },
    modalButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 0,
      paddingBottom: 30,
      paddingRight: 50,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingRight: 0,
        alignItems: 'center',
        paddingTop: 0,
      },
    },
    greyButton: {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#EFF1F5',
      borderRadius: 4,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#3C3E4B',
      lineHeight: '20px',
      marginRight: 20,
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 250ms',
      padding: '1px 10px',
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.2)',
        transition: 'background-color 250ms',
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: 3,
        marginBottom: 15,
        maxWidth: 294,
        marginRight: 0,
      },
    },
  });

interface IOwnProps {
  title: string;
  url: string;
  onClose: () => void;
}

type Props = WithStyles<typeof styles> & IOwnProps;

class AddEditLinkSuccessModal extends React.Component<Props> {
  render() {
    const { classes, url, title, onClose } = this.props;
    return (
      <Modal isOpen={true} onClose={onClose}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.modalInputContainer}>
          <Input
            id="1"
            inputClassName={classes.input}
            value={url}
            inputFormControlClassName={classes.inputFormControl}
          />
        </div>
        <div className={classes.modalButtonsContainer}>
          <a href={url} target="_blank" className={classes.greyButton}>
            <Typography className={classes.greyButtonText}>
              Open link
            </Typography>
          </a>
          <CopyToClipboard
            text={url}
            // tslint:disable-next-line:jsx-no-lambda
            onCopy={onClose}
          >
            <button className={classes.button}>
              <Typography className={classes.buttonText}>
                <img src={Copy} alt="" className={classes.img} /> Copy link
              </Typography>
            </button>
          </CopyToClipboard>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddEditLinkSuccessModal);
