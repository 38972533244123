import React, { Component } from 'react';
import { withStyles, WithStyles, Typography } from '@material-ui/core';
import loginStyles from './loginStyles';
import * as m from '..';
import { connect } from 'react-redux';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Paper } from '../../components';
import MediaparkLogo from '../../assets/img/mediapark-logo-white.png';
import GoogleLogo from '../../assets/img/googleLogo.svg';

type Props = WithStyles<typeof loginStyles> & typeof m.actions;

class Login extends Component<Props> {
  successResponseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    this.props.login(response as GoogleLoginResponse);
  };

  failureResponseGoogle = (error: any) => {
    // console.warn(error);
  };

  render() {
    const { classes } = this.props;
    const host = window.location.hostname;
    return (
      <Paper className={classes.paper}>
        <img src={MediaparkLogo} alt="" className={classes.img} />
        <div className={classes.brandContainer}>
          <Typography className={classes.mainText}>
            Create, edit and store unified
            <br /> looking links to the designs and prototypes.
          </Typography>
        </div>
        <GoogleLogin
          className={classes.popup}
          clientId="1881066601-8t2qsd5v3sonerbuj7e0r4m07s0tcjht.apps.googleusercontent.com"
          // tslint:disable-next-line:jsx-no-lambda
          render={renderProps => (
            <button onClick={renderProps!.onClick} className={classes.button}>
              <div className={classes.googleIconContainer}>
                <img src={GoogleLogo} alt="" className={classes.googleIcon} />
              </div>
              <div className={classes.signInText}>
                <Typography className={classes.text}>
                  Sign in with Google
                </Typography>
              </div>
            </button>
          )}
          buttonText="Login"
          onSuccess={this.successResponseGoogle}
          onFailure={this.failureResponseGoogle}
        />
      </Paper>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  m.actions,
)(withStyles(loginStyles)(Login));
