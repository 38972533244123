import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';

import './App.css';
import 'typeface-roboto';
import configureStore from './store';
import { Main } from './main';
import Redirect from './redirect/Redirect';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTypography: {
      body2: {
        fontSize: '16px',
        lineHeight: '26px',
      },
      body1: {
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 500,
      },
      h5: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '20px',
        marginBottom: '10px',
      },
      h6: {
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '26px',
        marginBottom: '15px',
      },
    },
  },
});

const history = createBrowserHistory();
export const store = configureStore(history);

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact={true} path="/" component={Main} />
              <Route
                exact={true}
                path="/:path?/:subpath?/:nonExistingPath?"
                component={Redirect}
              />
            </Switch>
          </ConnectedRouter>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
