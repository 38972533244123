import { actions } from '..';
import { links } from '../../api';
import { call, put } from 'redux-saga/effects';

export default function*(action: ReturnType<typeof actions.deleteStart>) {
  try {
    const id = action.payload;
    yield call(links.deleteLink, id);
    yield put(actions.initStart());
  } catch (ex) {
    yield put(actions.deleteFailure(ex.response && ex.response.error));
  }
}
