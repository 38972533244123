import { IState, ActionTypes } from './';
import * as m from './model';

const initialState: IState = {
  error: undefined,
  loading: true,
  user: null,
};

export const reducer = (
  state: IState = initialState,
  action: ActionTypes,
): IState => {
  switch (action.type) {
    case m.GET_ME_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case m.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case m.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case m.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        user: null,
        loading: false,
      };
    case m.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case m.GET_ME_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
