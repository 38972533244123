import { IRootState } from '../store';
import { ILink } from './components/Main';
import { default as IError } from '../model/interfaces/IError';

export interface IAddLinkModel {
  folder: string | undefined;
  redirectTo: string;
  slug: string;
}

export interface IUpdateLinkModel extends IAddLinkModel {
  id: number;
}

export interface IAddLinkRequestModel extends IAddLinkModel {
  userId: string;
  title: string;
}

export interface IEditLinkRequestModel extends IUpdateLinkModel {
  userId: string;
  title: string;
}

// Action types

export const INIT_START = 'guests/main/INIT_START';
export const INIT_SUCCESS = 'guests/main/INIT_SUCCESS';
export const INIT_FAILURE = 'guests/main/INIT_FAILURE';

export const ADD_LINK_START = 'guests/main/ADD_LINK_START';
export const ADD_LINK_SUCCES = 'guests/main/ADD_LINK_SUCCES';
export const ADD_LINK_FAILURE = 'guests/main/ADD_LINK_FAILURE';

export const EDIT_LINK_START = 'guests/main/EDIT_LINK_START';
export const EDIT_LINK_SUCCES = 'guests/main/EDIT_LINK_SUCCES';
export const EDIT_LINK_FAILURE = 'guests/main/EDIT_LINK_FAILURE';

export const DELETE_LINK_START = 'guests/main/DELETE_LINK_START';
export const DELETE_LINK_SUCCES = 'guests/main/DELETE_LINK_SUCCES';
export const DELETE_LINK_FAILURE = 'guests/main/DELETE_LINK_FAILURE';

// Action creators

export const actions = {
  initStart: () => ({
    type: INIT_START as typeof INIT_START,
  }),
  initSuccess: (links: ILink[]) => ({
    type: INIT_SUCCESS as typeof INIT_SUCCESS,
    payload: links,
  }),
  initFailure: (error: IError | undefined) => ({
    type: INIT_FAILURE as typeof INIT_FAILURE,
    payload: error,
  }),
  addStart: (link: IAddLinkModel) => ({
    type: ADD_LINK_START as typeof ADD_LINK_START,
    payload: link,
  }),
  addSuccess: (link: ILink) => ({
    type: ADD_LINK_SUCCES as typeof ADD_LINK_SUCCES,
    payload: link,
  }),
  addFailure: (error: IError | undefined) => ({
    type: ADD_LINK_FAILURE as typeof ADD_LINK_FAILURE,
    payload: error,
  }),
  editStart: (link: IUpdateLinkModel) => ({
    type: EDIT_LINK_START as typeof EDIT_LINK_START,
    payload: link,
  }),
  editSuccess: (link: ILink) => ({
    type: EDIT_LINK_SUCCES as typeof EDIT_LINK_SUCCES,
    payload: link,
  }),
  editFailure: (error: IError | undefined) => ({
    type: EDIT_LINK_FAILURE as typeof EDIT_LINK_FAILURE,
    payload: error,
  }),
  deleteStart: (id: number) => ({
    type: DELETE_LINK_START as typeof DELETE_LINK_START,
    payload: id,
  }),
  deleteSuccess: (links: ILink[]) => ({
    type: DELETE_LINK_SUCCES as typeof DELETE_LINK_SUCCES,
    payload: links,
  }),
  deleteFailure: (error: IError | undefined) => ({
    type: DELETE_LINK_FAILURE as typeof DELETE_LINK_FAILURE,
    payload: error,
  }),
};

// Selectors

export const selectors = {
  getOwn: (state: IRootState) => state.main,
};
