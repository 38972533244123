import React from 'react';
import { Button, WithStyles, withStyles } from '@material-ui/core';
import buttonStyles from './buttonStyles';
import { ButtonProps } from '@material-ui/core/Button';
import classNames from 'classnames';

interface IOwnProps {
  className?: string;
}
type Props = ButtonProps & WithStyles<typeof buttonStyles> & IOwnProps;

const CustomizedButton = (props: Props) => {
  const { classes, className, ...rest } = props;
  return (
    <Button
      {...rest}
      variant="contained"
      color="primary"
      className={className ? className : classes.button}
    >
      {props.children}
    </Button>
  );
};

export default withStyles(buttonStyles)(CustomizedButton);
