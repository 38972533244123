import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: '11px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 10px 37px 0 rgba(0,0,0,0.09)'
    },
    pointer: {
      cursor: 'pointer'
    }
  });
