import { all, takeEvery } from 'redux-saga/effects';
import * as m from '../model';

import { login } from './login';
import { logout } from './logout';
import { getMe } from './getMe';

export default function* rootSaga() {
  yield all([
    takeEvery(m.LOGIN_START, login),
    takeEvery(m.GET_ME_START, getMe),
    takeEvery(m.LOGOUT, logout)
  ]);
}
