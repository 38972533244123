import React from 'react';
import {
  withStyles,
  WithStyles,
  Typography,
  withTheme,
  WithTheme,
  createStyles,
  Theme,
} from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Input, Button } from '../../components';
import SearchIcon from '@material-ui/icons/Search';
import LogoutIcon from '../../assets/img/logout.svg';
import { getLinkType, LinkType } from '../../utils/links';

interface IOwnProps {
  onOpenAddLinkModal: () => void;
  onSearch: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onLogout: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      margin: 60,
    },
    header: {
      height: 37,
      color: '#616579',
      fontSize: 30,
      fontWeight: 'bold',
      lineHeight: '37px',
      marginRight: 'auto',
      minWidth: 170,
    },
    search: {
      maxWidth: 580,
      width: '100%',
      padding: '3px 12px 3px 12px',
    },
    greyButton: {
      maxWidth: 100,
      width: '100%',
      backgroundColor: 'rgba(97,101,121,0.09)',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#4A4A4A',
      lineHeight: '20px',
      marginRight: 17,
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.09)',
      },
    },
    inputFormControl: {
      marginBottom: '15px',
      display: 'flex',
      maxWidth: 580,
      width: '100%',
      marginRight: 24,
      [theme.breakpoints.down('md')]: {
        maxWidth: 330,
      },
    },
    mobileContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    mobilePageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingTop: 29,
      paddingBottom: 14,
      boxShadow: '0 1px 6px 0 rgba(0,0,0,0.09)',
      marginBottom: 18,
    },
    addLinkButton: {
      alignSelf: 'flex-end',
      background: '#FF8C00',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      width: 160,
      marginRight: 24,
      whiteSpace: 'nowrap',
      marginBottom: 22,
      '&:hover': {
        backgroundColor: '#FED731',
      },
    },
    inputFormControlMobile: {
      marginBottom: 33,
      display: 'flex',
      width: 290,
      position: 'relative',
      right: 13,
    },
    brandMobile: {
      height: 18,
      color: '#616579',
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: '18px',
      marginRight: 'auto',
      minWidth: 170,
      marginLeft: 20,
    },
    greyButtonMobile: {
      maxWidth: 80,
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      color: '#4A4A4A',
      lineHeight: '20px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'rgba(97,101,121,0.09)',
      },
    },
    logoutButton: {
      height: 20,
      cursor: 'pointer',
    },
    logoutButtonMobile: {
      top: '-6px',
      right: 14,
      position: 'relative',
    },
    logoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 40,
      cursor: 'pointer',
    },
    logoutButtonText: {
      color: '#616579',
      paddingRight: 11,
    },
  });

type Props = WithTheme & WithStyles<typeof styles> & IOwnProps;

const getWebName = () => {
  const host = window.location.hostname;
  const linkType = getLinkType(host);

  switch (linkType) {
    case LinkType.Design:
      return 'Design';
    case LinkType.Prototype:
      return 'Wireframe';
    case LinkType.Animation:
      return 'Animation';
    default:
      return 'Design';
  }
};

const PageHeader = (props: Props) => {
  const { theme, classes } = props;
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const host = window.location.hostname;
  const linkType = getLinkType(host);

  if (matches) {
    return (
      <div className={classes.mobileContainer}>
        <div className={classes.mobilePageHeader}>
          <Typography className={classes.brandMobile}>
            {getWebName()} links
          </Typography>
          <img
            src={LogoutIcon}
            alt=""
            onClick={props.onLogout}
            className={classes.logoutButtonMobile}
          />
        </div>
        <Button
          onClick={props.onOpenAddLinkModal}
          className={classes.addLinkButton}
        >
          Add new link
        </Button>
        <Input
          id="2"
          placeholder="Search by slug..."
          inputClassName={classes.search}
          inputFormControlClassName={classes.inputFormControlMobile}
          EndAdornment={<SearchIcon />}
          onChange={props.onSearch}
        />
      </div>
    );
  }

  return (
    <div className={classes.headerContainer}>
      <Typography className={classes.header}>{getWebName()} links</Typography>
      <Input
        id="2"
        placeholder="Search by slug..."
        inputClassName={classes.search}
        inputFormControlClassName={classes.inputFormControl}
        EndAdornment={<SearchIcon />}
        onChange={props.onSearch}
      />
      <Button
        onClick={props.onOpenAddLinkModal}
        className={classes.addLinkButton}
      >
        Add new link
      </Button>
      <div className={classes.logoutContainer} onClick={props.onLogout}>
        <Typography variant="body2" className={classes.logoutButtonText}>
          Log out
        </Typography>
        <img src={LogoutIcon} alt="" className={classes.logoutButton} />
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(PageHeader));
