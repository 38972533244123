import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    button: {
      background: '#FF8C00',
      borderRadius: 3,
      boxShadow: 'none',
      fontWeight: 400,
      height: 40,
      color: '#FFFFFF',
      lineHeight: '20px',
      width: 200,
      marginRight: 28,
      whiteSpace: 'nowrap',
      '&:hover': {
        background: '#FED731',
      },
    },
  });
