import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    inputFormControl: {
      marginBottom: 15,
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 26,
        width: '100%',
      },
    },
    inputContainer: {
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    input: {
      border: '1px solid #DADAE0',
      borderRadius: 3,
      padding: '3px 12px 3px 12px',
      color: '#616579',
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {},
    },
    label: {
      color: '#616579',
      // fontFamily: 'Proxima Nova',
      fontSize: 16,
      lineHeight: '20px',
      textAlign: 'center',
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 8,
      },
    },
    icon: {
      position: 'relative',
      left: -35,
      color: '#616579',
      top: 6,
    },
    asterisk: {
      color: 'red',
    },
    error: {
      color: '#F03738',
      position: 'relative',
      left: 106,
      paddingTop: 5,
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    required: {
      color: '#F03738',
    },
  });
